:root {
  --purple0-light: #A974DA;
  --purple1-light: #E1BFFF;
  --purple2-light: #F5E9FF;
  --grey0-light: #D9D9D9;
  --grey-accent: #9A9A9A;
  --bg-color: #FBFCFF;
  --text-color: #191125;
  --accent-color: #ad81d3;
  --accent-color-hover: #d9b3fb;
  --white: #FBFCFF;
}

[data-theme="dark"] {
  --grey0-dark: #1D1726;
  --grey-accent: #9A9A9A;
  --bg-color: #191125;
  --text-color: #FBFCFF;
  --accent-color: #CBA4ED;
  --accent-color-hover: #d9b7f7;
  --white: #FBFCFF;
}

.purple {
  color: var(--accent-color);
}

a {
  color: var(--accent-color);
  text-decoration: none;
}

a:hover {
  color: var(--accent-color-hover);
}

a.visited {
  color: var(--accent-color);
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Nunito Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  