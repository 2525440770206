.project-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* You can add more default styles here if needed */
    box-shadow: inset 0 0 0 2px var(--purple2-light); /* Inner border color and size */
    background-color: transparent; /* Transparent background */
    color: var(--text-color); /* Text color */
    border-radius: 20px;
    padding: 20px;
    gap: 30px;
}



@media screen and (max-width: 784px) {
    .project-item {
        flex-direction: column;
    }
}


.project-item.dark-mode {
    box-shadow: none; /* No border for dark mode */
    background-color: var(--grey0-dark); /* Filled background color for dark mode */
    color: var(--text-color); /* Change text color for better contrast */
}

.project-img {
    width: 150px;
    height: auto;
}