header {
    background-color: var(--bg-color);
    height: 50px;
    display: flex;
    justify-content: space-between; /* Keeps the logo on the left */
    align-items: center; 
    padding: 10px 20px;
    width: 80vw;
    font-weight: 500;
}

.site-logo {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.site-logo img {
    height: 70%;
    width: auto;
}

/* Align nav and theme button */
.header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 20px;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 10px;
}

nav a {
    text-decoration: none;
    padding: 10px;
    color: var(--text-color); /* Default text color */
    font-weight: 500;
}

/* Active link styling with accent color */
.active-link {
    color: var(--accent-color); /* Accent color */
}

/* Dropdown styles */
ul.dropdown {
    width: auto;
    background: var(--bg-color);
    position: absolute;
    z-index: 999;
    display: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.dropdown-container:hover ul.dropdown {
    display: block;
}

ul.dropdown li {
    display: block;
    padding: 10px;
}

ul.dropdown li a {
    color: var(--text-color);
    text-decoration: none;
}

ul.dropdown li a:hover {
    color: var(--accent-color);
}

a.resume-dropdown {
    color: var(--text-color);
}

@media screen and (max-width: 768px) {
    .resume-dropdown {
        display: none;
    }
    
    /* Dropdown styles */
    ul.dropdown {
        width: auto;
        background: transparent;
        position: absolute;
        z-index: 999;
        display: none;
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        padding: 0;
        background-color: var(--bg-color);
    }

    .dropdown-container:hover ul.dropdown {
        display: block;
        align-items: center;
        justify-content: center;
    }

    ul.dropdown li {
        display: block;
        padding: 20px 10px;
        align-items: center;
        justify-content: center;
    }

    

    ul.dropdown li a {
        color: var(--text-color);
        text-decoration: none;
    }

    ul.dropdown li a:hover {
        color: var(--accent-color);
    }
    
}

/* Hamburger Icon */
.hamburger-icon {
    display: none; /* Hidden on desktop */
    cursor: pointer;
    z-index: 100;
}

/* Mobile menu styling */
.mobile-menu {
    display: none; /* Initially hidden */
    position: absolute;
    top: -100vh;
    right: 0;
    background-color: var(--bg-color);
    width: 100%;
    text-align: center;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.mobile-menu.open {
    display: block; /* Show when toggled */
    transform: translateY(100vh);
}

.mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;          /* Flexbox for the list */
    flex-direction: column; /* Arrange items in a column */
    align-items: center;     /* Center align the items */
    gap: 20px;               /* Add some space between the items */
}

.mobile-menu ul li {
    width: 100%;             /* Make each list item take full width */
    text-align: center;      /* Center the text */
    padding: 10px 0;
}

.mobile-menu ul li a {
    color: var(--text-color);
    font-size: 18px;
}

/* Media queries for mobile view */
@media (max-width: 768px) {
    .header-right {
        display: none; /* Hide desktop nav on mobile */
    }

    .hamburger-icon {
        display: block; /* Show hamburger icon on mobile */
        color: var(--accent-color);
    }
}