.button {
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    color: var(--accent-color);
    cursor: pointer;
    font-weight: 500;
    font-family: 'Open Sans';
}



.button.primary {
    background-color: var(--accent-color);
    color: var(--bg-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button.primary:hover {
    background-color: var(--accent-color-hover);
}

.button.secondary {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--accent-color); /* Inner border color and size */
    color: var(--accent-color);
    transition: box-shadow 0.3s ease, color 0.3s ease;
    font-weight: 500;

}

.button.secondary:hover {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--accent-color-hover); /* Inner border color and size */
    color: var(--accent-color-hover);
}